@import '~@betwixt/common/src/scss/variables.scss';

#Shop{
    color: black;

    .ModuleList{
        .ModuleItem{

            .moduleImage{
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;

                @include media-breakpoint-down(sm) {
                    min-height: 100px;
                }
            }

            .subscriptions {

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                    align-items: normal;
                }

                header{
                    @include media-breakpoint-down(sm){
                        padding: 15px 0 0 !important;
                        border-bottom: 1px solid $grey;
                    }
                    @include media-breakpoint-up(sm){
                        margin-right: 15px;
                    }
                }

                .requiresAction{
                    text-align: center;
                    border-left: 1px solid $grey_light;
                    padding-left: 20px;
                    width: 50%;
                    color: red;

                    @include media-breakpoint-down(sm) {
                        text-align: justify;
                        border-left: transparent;
                        margin-top: 15px;
                        padding-left: 0;
                        width: auto;
                    }
                }

                .Plans{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    
                    .Plan{
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        padding: 10px 0;
                        border-bottom: 1px solid $grey_light;

                        &:last-child{
                            border-bottom: 0;
                        }

                        .info{
                            flex: 1 1 auto;
                            padding: 0 10px;

                            .nickname{

                            }
                            .amount{
                                color: $blue;
                                font-size: 20px;
                                line-height: 24px;

                                .base_price{
                                    color: #ea8181;
                                    text-decoration: line-through;
                                    font-size: 15px;
                                    line-height: 17px;
                                    margin-top: 3px;
                                }  
                            }
                            .trial{
                                color: $blue_dark;
                                padding: 0 0 4px 0;
                            }
                        }

                        .cta{
                            display: flex;
                            flex-direction: column;

                            @include media-breakpoint-down(sm){
                                flex-direction: row;
                            }
                        }
                    }
                }
            }
        }
    }

    .subscriptions{

        #other{

        }

        #purchased{
            .subscription_row{

                cursor: pointer;
                margin-top: 10px;

                &:first-child{
                    margin-top: 0;
                }

                > .row{
                    padding: 10px;
                    font-size: 14px;
                    background-color: #f5f5f5;
                    border: 1px solid #f5f5f5;
                    border-radius: 4px;
                    
                    &.opened{
                        color: white;
                        border: 1px solid #ffcf3d;
                        background-color: #ffcf3d;
                        margin-bottom: 5px;

                        .moduleId{
                            color: white;
                        }
                    }
    
                    &:not(.opened):hover{
                        border: 1px solid #969595;
                        background-color: white;

                        .moduleId{
                            color: #3c3c3b;
                        }
                    }

                    .moduleId{
                        text-transform: capitalize;
                        font-size: 17px;
                        color: #6f6f6e;
                    }
                    .expired{

                    }
                    .status{
                        font-size: 13px;
                        text-transform: uppercase;
                        padding-left: 3px;

                        &.actived{
                            color: #39b54a;
                        }   
                        &.disactived{
                            color: #ff0000;
                        }
                    }
                    .actions{

                    }
                }
                .SubscriptionRowDetails{
                    
                    border: 1px solid #f5f5f5;
                    border-radius: 4px;
                    background-color: white;
                    padding: 10px 20px;

                    margin-right: -15px;
                    margin-left: -15px;

                    label{
                        color: #b3b3b3;
                        font-size: 11px;
                        text-transform: uppercase;
                        margin-bottom: 0;
                        font-weight: 300;
                    }
                    p{
                        line-height: 17px;
                        margin-bottom: 20px;
                    }

                    h3{
                        color: #b3b3b3;
                        line-height: 27px;
                        margin-top: 20px;
                        font-size: 20px;
                        font-weight: 300;
                    }

                    ul{
                        &.menu{
                            padding: 7px 0;
                            margin: 0;
                            text-align: right;
                            list-style: none;
                            font-size: 14px;
                    
                            li{
                                color: #666666;

                                &:hover{
                                    cursor: pointer;
                                    color: #1a1a1a;
                                }

                                @include media-breakpoint-down(sm) {
                                    border-bottom: 1px solid #c0c0bf;
                                    text-align: center;
                                    padding: 10px;
                    
                                    &:last-child{
                                        border-bottom: 1px solid transparent
                                    }
                                }
                            }

                        }
                    }

                    table{
                        thead{
                            th{
                                border-top: 1px solid transparent;
                                color: #b3b3b3;
                                font-size: 13px;
                                font-weight: 300;
                            }
                        }
                        tbody{
                            td{
                                font-size: 16px;
                                font-weight: 400;
                                color: #4d4d4d;
                            }
                        }
                    }

                }
            }
        }

        .listing{
            
            margin-bottom: 20px;
            
            .module{
                background-color: white;
                border-bottom: 1px solid #f2f2f2;
                padding: 13px;

                .image{
                    background-color: $grey_medium;
                    height:115px;
                    margin-bottom: 10px;
                    border-radius: 6px;
                }
                h3{
                    font-size: 18px;
                    color: $blue;
                    margin: 0;

                    @include media-breakpoint-down(sm) {
                        text-align: center;
                    }
                }
                p{
                    font-size: 14px !important;
                    font-weight: inherit !important;
                    color: $grey_medium;
                    margin-bottom: 0;

                    @include media-breakpoint-down(sm) {
                        text-align: center;
                    }
                }

                .price{
                    font-size: 18px;
                    color: black;
                    font-weight: 700;
                    
                    @include media-breakpoint-down(sm) {
                        margin-top: 10px;
                    }
                }
                .moreDetails{
                    font-size: 11px;
                    text-transform: uppercase;
                    cursor: pointer;

                    @include media-breakpoint-down(sm) {
                        margin-bottom: 10px;
                    }
                }

                .addToBasket{
                    font-size: 20px;
                    float: right;
                    display: block;
                    color: #b3b3b3;
                    cursor: pointer;

                    &:hover{
                        color: $grey_medium;
                    }

                    @include media-breakpoint-down(sm) {
                        float: none;
                        text-align: center;
                    }
                }
                
                &:hover{
                    background-color: #f4f4f4;
                    border-bottom: 1px solid white;
                }

                &.selected{
                    background-color: $grey_light;
                    border-bottom: 1px solid white;

                    h3{
                        color: $blue_dark;
                    }
                    p{
                        color: #333333;
                    }
                }

            }
            
        }
    }

}