@import '~@betwixt/common/src/scss/variables.scss';

.modal-title{
    color: $blue_primary;
}

.modal-backdrop{
    opacity: 0.5;
    background-color: #d0d4e0;
}

.modal-dialog {

    @include media-breakpoint-down(xs){
        margin: 1rem;
    }

    &.fullscreen{
        max-width: 100%;
        margin: 1.75rem;
        height: calc(100% - 150px);

        .modal-content{
            height: 100%;
        }
    }

    .modal-content {
        border-radius: 1.2rem;
        border: 1px solid rgba(255, 255, 255, 0.5);
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        margin-bottom: 80px;
        position: relative;

        .loading{
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            top: 20%;
        }
        .modal-header{
            border-bottom: none;
        }
        .modal-body{
            padding: 0 1rem 30px;

            .cta{
                margin: 10px auto;
                text-align: right;

                button{
                    margin-left: 5px;
                }
            }
        }
    }
}

.modal-footer{
    border-top: none;
    
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #b6c1ccd6;
    padding-bottom: 30px;
    margin: 0!important;
    z-index: 100;

    box-shadow: 0 0 40px 0 #7f91a2;

    animation-name: show;
    animation-duration: 0.7s;
    
    button{
        height: 45px;
        margin-left: 5px;
    }

    @keyframes show {
        0% { visibility: hidden; }
        50% { visibility: hidden; }
        60% { bottom: -75px; visibility: visible; }
        100% { bottom: 0; }
    }
}