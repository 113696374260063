@import '~@betwixt/common/src/scss/variables.scss';

header {
    &.SectionHeader {

        .breadcrumb{
            padding: 0;
            background-color: transparent;
        }

        .primary{
            display: flex;
            flex-direction: row;
            align-items: baseline;

            @include media-breakpoint-down(sm) {
                align-items: flex-start;
            }

            .detail {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: baseline;
                flex: 1;

                @include media-breakpoint-down(sm) {
                    flex-direction: column;
                }

                .content{
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 auto;
                    width: 100%;
                    padding: 0 !important;

                    .title{
                        margin-bottom: 0;
                        min-height: 36px;
                        position: relative;
    
                        @include media-breakpoint-down(xs) {
                            input{
                                text-overflow: ellipsis;
                                max-width: 230px;
                            }
                        }
                        
                        .editable{
                            display: flex;
                            justify-content: space-between;
    
                            @include media-breakpoint-down(sm){
                                width: 100%;
                            }
    
                            &.editing{
                                top: -7px;
                                left: -12px;
                                padding: 5px 5px 5px 10px;
                                position: absolute;
                                background-color: rgba(255, 255, 255, 0.8);
                                border-radius: 15px;
                                border: 2px solid white;
                                z-index: 1;
    
                                @include media-breakpoint-down(sm){
                                    flex-direction: column;
                                    width: 100%;
                                }
                            }
    
                            input{
                                @include media-breakpoint-down(sm){
                                    margin: auto;
                                }
                            }
                            .edit_cta{
                                display: inline-block;
    
                                @include media-breakpoint-down(sm){
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }
                
                .menu{
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    a,
                    button{
                        margin: auto 7px;
                    }

                    @include media-breakpoint-down(sm){
                        margin-top: 15px;
                        justify-content: center;
                        width: 100%;
                    }
                }
            }
            .cta {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: $grey_dark !important;

                @include media-breakpoint-down(sm){
                    flex-direction: column-reverse;
                    position: fixed;
                    right: 0;
                    top: 0;
                    padding: 20px 0 10px;
                    background-color: rgba($grey_light, 0.85);
                    border-bottom-left-radius: 10px;
                    z-index: 2;
                }
                
                div,
                button {
                    margin: 0 4px;
                    display: inline-block;
                    
                    svg{
                        font-size: 18px;

                        @include media-breakpoint-down(sm){
                            font-size: 28px;
                            margin-bottom: 7px;
                        }
                    }
                    .txt{
                        font-size: 15px;

                        @include media-breakpoint-down(sm){
                            display: none;
                        }
                    }

                    a{
                        color: $grey_dark !important;
                    }
                }
            }
        }
    }
}
