@import '~@betwixt/common/src/scss/variables.scss';

.section_lists{

    .section_title{
        font-size: 1rem;
        line-height: 2rem;
        color: $grey_light_medium;
        padding: 0 1rem;
        z-index: 1;
    }

}