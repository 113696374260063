@import '~antd/dist/antd.css';
@import '~@betwixt/common/src/scss/variables.scss';
@import './customization.scss';
@import './forms.scss';
@import './table.scss';
@import './modules.scss';
@import '~@betwixt/common/src/scss/globals.scss';


* { 
    //transition: all 0.3s ease-in-out;
 }

html{
  
    height: 100%;
    -webkit-overflow-scrolling: touch;
    
    body {
        transition: all 0.3s;
        height: 100%;
        font-family: Lato, sans-serif;
        background: #ecf0f3;
        background-attachment: fixed;
        
        &.darkMode{
            background-color: $dark;
        }

        &.headerInSticky{
            #root{
                .App{
                    main{
                        padding-top: 170px;
                    }
                }
            }
        }

        #portal-root{
            display: none;
            position: sticky;
            top: 0;
            z-index: 9999999;
        }

        #root{

            height: 100%;

            &[aria-hidden="true"]{
                filter: blur(7px);
            }

            .App{
                height: 100%;
                min-height: 100%;
        
                .stage{
                    display: flex;
                    flex-direction: column;
                    min-height: 100%;
                    align-items: stretch;    
                    /*
                    @include media-breakpoint-down(sm) {
                        height: 100%;
                    }
                    */
                    main{
                        flex: 1 1 auto;
                    }
                }

                main{
                    height: 100%;
                    position: relative;
                    display: flex;
                    flex-direction: column;

                    > section {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        padding: 0 3vw 3rem;
                        
                        // manage width
                        width: 100%;

                        &.navsAside_isVisible {
                            
                            @include media-breakpoint-up(sm) {
                                
                                @media (orientation: landscape) {
                                    width: calc(100% - 20rem);
                                }
                                @media (orientation: portrait) {
                                    width: 100%;
                                    /*width: calc(100% - 20rem);*/
                                }
                            }
                            @include media-breakpoint-between(md, sm){
                                width: calc(100% - 200px);
                            }

                            @media (orientation: landscape) {
                            }
                            @media (orientation: portrait) {
                            }


                            @include media-breakpoint-down(xs){
                                filter: blur(7px);
                            }

                        }
                        // ************

                        @include media-breakpoint-down(sm) {
                            padding: 0 3vw 3vw 2rem;

                            &[data-pagename="login"],
                            &[data-pagename="signup"],
                            &[data-pagename="error"]{
                                padding: 0 3vw 3vw;
                            }
                        }

                        &[data-pagename="login"]{
                            justify-content: center;
                        }

                        header{ 
                            // primary header section
                            //padding: 0.6rem 1rem; 
                            padding: 0;

                            h1{
                                font-size: 27px;
                                color: $blue_primary;

                                @include media-breakpoint-down(sm) {
                                    font-size: 20px;
                                }
                            }
                            p{
                                font-size: 18px;
                                font-weight: 300;
                                margin-bottom: 30px;
                            }
                        }

                        .content{
                            padding: 0 1rem 1rem;
                        }

                    }

                    .section{
                        border-radius: 20px;
                        margin-top: 1rem;
                        position: relative;
                        
                        & .section{
                            width: 100%;

                            @include media-breakpoint-down(sm) {
                                padding: 0 20px; 
                            }
                            @include media-breakpoint-down(sm) {
                                padding: 0; 
                            }

                            @at-root .withAside header{
                                padding: 25px 0 !important;
                            }
                        }
                        
                        header{
                            // header inside a section
                            position: relative;

                            &:first-child{

                                @include media-breakpoint-down(sm) {
                                    text-align: center;
                                }
                            }
                        }

                        &.primary{
                            background-color: white;
                            box-shadow: 0 2px 0 rgba(90,97,105,.11),0 4px 8px rgba(90,97,105,.12),0 10px 10px rgba(90,97,105,.06),0 7px 70px rgba(90,97,105,.1);
                            @include media-breakpoint-down(sm) {
                                //border: 10px solid $grey_light;
                            }
                        }

                        &.dark{
                            background-color: $grey_dark;

                            .SectionTabs{
                                color: $black;
                                background-color: $grey_medium;
                                border-color: $dark;
                            }
                        }

                        &.dark-style{
                            background-color: $grey_dark;

                            .SectionTabs{
                                color: $black;
                                background-color: $grey_medium;
                                border-color: $dark;
                            }
                        }

                        &.blue-medium-style {
                            background-color: $blue_custom_1_medium;
                            color: white;
                            box-shadow: none;

                            label{
                                color: white;
                            }
                        }

                        &.withAside {
                            padding: 0 15px 0 15px;

                            > div {
                                & > :first-of-type{
                                    padding: 20px;
                                    background-color: $blue_custom_1; 
                                    border-top-left-radius: 20px;
                                    border-bottom-left-radius: 20px;

                                    h1, h2, h3, h4{
                                        color: white;
                                    }

                                    h4{
                                        font-size: 20px;
                                    }

                                    p{
                                        margin-bottom: 0;
                                    }

                                    @include media-breakpoint-down(sm) {
                                        border-top-left-radius: 0;
                                        border-bottom-left-radius: 0;
                                        border-top-left-radius: 10px;
                                        border-top-right-radius: 10px;
                                    }
                                }
                                & > :nth-child(2){
                                    padding: 0 20px;

                                    h4{
                                        font-size: 17px;
                                        margin-top: 1.7rem;
                                        color: #333333;
                                    }

                                }
                            }
                        }

                        &[data-pagename="welcome"]{
                            background-color: transparent;
                            box-shadow: none;
                        }

                        h1{
                            line-height: inherit;
                            font-size: 23px;
                            color: $blue_primary;
                            text-align: left;
                        }

                        p{
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 400;
                            margin-bottom: 30px;
                        }

                    }
                }

                #tooltip{
                    top: 0;
                    left: 0;
                    right: 0;
                    color: white;
                    padding: 5px;
                    border-bottom: 1px solid #f4f4f4;
                    cursor: pointer;
                    position: sticky;
                    z-index: 9999;

                    #toggle{
                        right: 0;
                        position: absolute;
                        z-index: 100;
                        cursor: pointer;
                        display: none;
                    }

                    svg{
                        margin: 0 10px
                    }

                    &.development{
                        background-color: #1787f6;
                        #toggle{
                            color: #1787f6;
                        }
                    }
                    &.test{
                        background-color: #fecc47;
                        #toggle{
                            color: #fecc47;
                        }
                    }

                    &.compact{
                        padding: 0;
                        .container{
                            display: none;
                        }
                        #toggle{
                            display: block;
                        }
                    }
                }

                .pagination{
                    background-color: $grey_light_3;
                    margin: 50px auto 0;
                    display: inline-block;
                    padding: 5px 30px;
                    border-radius: 50px;
                }
            }
        }
    }
}

.container_sticky{
    width: 100%;
    
    &.sticky{
        left: 0;
        z-index: 2;
        position: absolute;
    }
}

.hidden{
    display: none !important;
}

.info{
    font-size: 12px;
    color: #b7b7b7;
}

.editable{
    cursor: pointer;
}

.handle-btn{
    height: 35px;
    display: flex;
    align-items: center;
    cursor: row-resize;

    .handle{
        position: relative;
        top: 1px;
        display: block;
        width: 18px;
        height: 11px;
        opacity: .25;
        cursor: row-resize;
        background: -webkit-linear-gradient(top,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
        background: linear-gradient(180deg,#000,#000 20%,#fff 0,#fff 40%,#000 0,#000 60%,#fff 0,#fff 80%,#000 0,#000);
    }
}