@import '~@betwixt/common/src/scss/variables.scss';

a,
button{
    border: none;
    background-color: transparent;

    &.btn{
        border: none;
        border-radius: 4px;
        line-height: 1.4;

        &.btn-border{
            border: none;
            border-radius: 25px;
            padding: 6px 20px;
        }

        &.btn-default{
            border: none;
            color: white;
            background-color: $grey_medium !important;
        }

        &.btn-action{
            color: white;
            background-color: $blue_primary !important;
        }

        &.btn-small{
            font-size: 13px;
            line-height: normal;
        }
    }

    &:focus {
        outline: none;
    }
    &:active {
        color: #bbb;
    }
}