@import '~@betwixt/common/src/scss/variables.scss';
@import './cta.scss';

label{
    color: #4d4d4d;
    font-size: 14px;
    padding: 0 7px;
    margin-bottom: 2px;

    &.af_label_type{
        font-size: 15px;
        color: white;
        background-color: $blue_primary;
        padding: 7px 15px 7px 25px;
        margin: 30px auto 17px;
        margin-left: -6px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        text-align: right;
    }
}

textarea{
    &.form-control{
        min-height: 120px;
    }
}

textarea,
select,
input{
    outline: none;
    background-color: transparent;
    border-radius: 4px;
    padding: 0 7px;
    border: none;
    height: 36px;
    //font-size: 15px !important;

    &[type="checkbox"]{
        height: auto;
        margin: auto 7px;
    }

    &::placeholder{
        color: $grey_medium !important;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $grey_light inset;
    }

    &.with-border{
        border: 1px solid $grey_light !important;
    }

    &.with-grey-bg{
        background-color: $grey_light !important;
    }

    &.form-control{
        height: 36px;
        border: none;
        border-radius: 4px;

        &.minimal{
            border: 1px solid;
        }
    }

    &.ant-input{
        border: none;
        line-height: 2;
        border-radius: 4px;
    }

    &.minimal{
        background-color: transparent;
        border-bottom: 1px solid #dedede;
        color: grey;
        &:focus,
        &:hover{
            border-bottom: 1px solid;
        }
    }
}

.input-group{
    .form-control{
        border: none;
    }
    .ant-select{
        input{
            line-height: 1.95;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
}

.form-group{
    &.error{
        input{
            border: 1px solid red;
        }
        .error{
            color: red;
            padding: 7px 7px 0;
        }
        &.genericError{
            font-size: 17px;
            color: red;
            text-align: center;
            border: 1px solid red;
            display: block;
            padding: 3px 10px;
            border-radius: 20px;
        }
    }
}