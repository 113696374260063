@import '~@betwixt/common/src/scss/variables.scss';

#NavsAsideExtends_MM{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-height: 80px;
    background-color: #b6c1cc;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top: 1px solid #a1b0bf;
    color: white;
    
    @include media-breakpoint-down(sm){
        flex-direction: column;
        padding-bottom: 10px;
    }

    @include media-breakpoint-down(xs){
        flex-direction: row;
    }

    .btn{
        color: white;

        &.btn--public-page{
    
        }

        &.qr-code{
    
        }
    }
}