@import "@betwixt/common/src/scss/variables.scss";

/*
**********************************
********** ANT DESIGN ************
**********************************
*/
.tagBar {
    background-color: $grey_light;
    min-height: 32px;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 2px;
    display: flex;

    .ant-tag {
        border-radius: 6px;
        margin-right: 2px;
        display: flex;
        align-items: center;
    }
}

.CTag {
    display: inline-block;

    .ctag-checkable {
        padding: 0 7px 0 0;
        border-top-right-radius: 11px;
        border-bottom-right-radius: 10px;
    }

    a{
        padding: 2px 7px;
        margin: 2px 5px 2px 0;
        
        &:hover{
            color: $blue; 
        }

        &.active{
            background-color: $blue;
            border-radius: 4px;
            color: white;

            &:hover{
                color: white; 
            }
        }
    }
}

.ant-tag {
    font-size: 14px;
    padding: 2px 10px;
    display: inline-flex;
    align-items: center;
    border: none;
    margin: 2px;

    .anticon {
        &.anticon-plus {
            margin: 2px 4px 2px 0;
        }
    }
}

input {
    &.ant-input {
        height: 36px;
    }
}
.ant-message-custom-content {
    display: flex;
    align-items: center;
}

.ant-alert{
    
}

/*
**********************************
*********** BOOTSTRAP ************
**********************************
*/

.alert{
    margin-bottom: 10px;
    padding: 5px 10px;
}

.input-group-text{
    border: none;
}