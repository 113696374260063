@import '~@betwixt/common/src/scss/variables.scss';

@mixin isCompact() {
    
    header{
        > section{
            &#profile{
                display: block;
                justify-content: center;

                .content{
                    display: none
                }
                .cta{
                    min-width: 100%;
                    margin-top: 20px;
                }
            }
        }
    }

    #container_menu{
        position: relative;

        nav{
            ul{
                li{
                    .btn_back{
                        margin: auto;
                    }
                    a{
                        svg{
                            min-width: 100% !important;
                            margin: 0 !important;
                        }
                        span{
                            display: none;
                        }
                    }
                }
            }
        }
    }

    #logo{
        svg{
            width: auto;
            margin: auto 20%;
        }
    }
}

main.flex-row aside#sidebar{
    margin-left: -20rem;
    transition: all 0.3s ease-in;
    padding: 2vh 0 25px 0;
    
    // manage width
    min-width: 20rem;
    // ************

    &.isVisible{
        margin-left: 0;  
        transition: all 0.3s ease-out;
        padding: 2vh 0 25px 25px;
    }

    @include media-breakpoint-between(md, sm){
        min-width: 200px;
        margin-left: -200px; 

        &.isVisible{
            min-width: 120px;
            margin-left: 0; 
        }
    }

    @include media-breakpoint-down(sm){
        min-width: 120px;
        margin-left: -120px; 

        &.isVisible{
            min-width: 145px;
            margin-left: 0; 
        }
    }

    @include media-breakpoint-down(xs){
        min-width: 0 !important;
        margin-left: 0 !important;
        padding: 25px 0 !important;
        position: fixed !important;
    }

    .containerSidebar{

        transition: all 0.3s ease-out;
        box-shadow: 0 2px 0 rgba(90,97,105,.11),0 4px 8px rgba(90,97,105,.12),0 10px 10px rgba(90,97,105,.06),0 7px 70px rgba(90,97,105,.1);
        
        &.isVisible{
            transition: all 0.3s ease-out;
        }
        
        @include media-breakpoint-down(sm){
            width: 120px;
        }

        @include media-breakpoint-down(xs){
            top: 15px;
            width: 300px;
            position: absolute;
            z-index: 2;
            left: -300px !important;

            &.isVisible{
                left: 25px !important;                
            }
        }

        @media (max-width: 330px) {
            width: 245px;
            left: -245px !important;

            &.isVisible{
                left: 25px !important;                
            }
        }

    }
}


main.flex-row aside#sidebar{

    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 3;
    align-self: flex-start;

    //height: calc(100% - 50px);

    .containerSidebar{
        display: flex;
        flex-direction: column;
        
        background-color: white;
        border-radius: 20px;
        height: calc(100% - 50px);
        min-height: 30rem;

        &.isVisible{
            @include media-breakpoint-down(xs){
                box-shadow: 1rem 2rem 2.5rem $grey;
            }
        }

        @include media-breakpoint-between(sm, sm){
            @include isCompact();
        }

        header{

            position: relative;

            > section{
                &#profile{
                    display: flex;
                    flex-flow: row wrap;
                    align-items: center;
                    padding: 30px 30px 20px;
                    border-bottom: 1px solid $grey_light;

                    .imageProfile{
                        background-color: $orange;
                        border-radius: 30px;
                        width: 54px;
                        height: 54px;
                        text-align: center;
                        line-height: 54px;
                        color: white;
                        font-size: 22px;
                    }
                    .content{
                        min-width: 50%;
                        padding: 0 10px;

                        .name_profile{
                            font-size: 18px;
                            line-height: 17px;
                            text-transform: capitalize;
                            color: $orange;
                            text-overflow: ellipsis;
                            width: 95px;
                            overflow: hidden;
                        }
                        .companyname_profile{
                            font-size: 13px;
                            text-transform: capitalize;
                            text-overflow: ellipsis;
                            width: 95px;
                            overflow: hidden;
                        }
                        .edit_profile {
                            font-size: 10px;
                            line-height: 18px;
                            text-transform: uppercase;
                            text-align: left;

                            a {
                                color: $grey;
                            }
                        }
                    } 
                    .cta{
                        min-width: 27%;
                        text-align: center;

                        a{
                            font-size: 14px;
                            color: $grey;
                            cursor: pointer;

                            &:hover{
                                color: $grey_medium;
                            }
                        }
                    }
                }
            }

            #controllerAside{

                position: absolute;
                z-index: 2;
                right: -30px;
                top: 4px;
                width: 30px;
                border-top-right-radius: 7px;
                border-bottom-right-radius: 7px;
                transform: translate3d(0,0,0);
        
                ul{
                    list-style: none;
                    margin: 0;
                    padding: 0;
                
                    li{

                        border-bottom: 1px solid #f2f2f2;

                        &:last-child{
                            border-bottom: none;
                        }
                        
                        &#toggleAside{
                            width: auto;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            padding: 7px 6px;
                            cursor: pointer;
                    
                            div{
                                background-color: #999;
                                width: 100%;
                                height: 4px;
                                border-radius: 10px;
                                margin-bottom: 3px;

                                &:last-child{
                                    margin-bottom:0;
                                }
                            }
                            
                            &:hover{
                                div{
                                    background-color: #6b6a6a;
                                }
                            }

                            &.opened {
                                div{
                                    &:nth-child(1){ 
                                        transform: rotate(-45deg)translate(-5px, 5px);
                                    }
                                    &:nth-child(2){
                                        display: none;
                                    }
                                    &:nth-child(3){
                                        transform: rotate(45deg)translate(0px, 0px);
                                    }
                                }
                            }

                            &.closed{
                                div{
                                    width: 5px;
                                    height: 5px;
                                }
                            }
                        }

                        &#minimizeAside {
                            @include media-breakpoint-down(sm) {
                                display: none
                            }
                        }
                        
                    }
                }
            }
        }

        #container_menu{

            flex: auto;
            position: relative;

            nav{
                
                ul{
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    li{
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        height: 60px;
                        max-height: 60px;
                        border-bottom: 1px solid $grey_light;
                        
                        &:last-child{
                            border-bottom: none;
                        }

                        &#primaryModule{
                            a{
                                color: $blue_dark;
                            }
                        }

                        a{  
                            display: flex;
                            align-items: center;
                            height: 100%;
                            width: 100%;
                            padding: 0 43px;
                            text-transform: capitalize;
                            text-decoration: none;
                            color: $grey_medium;

                            &:hover{
                                color: $grey_dark;
                            }

                            &.active{
                                color: $blue_primary; 
                            }

                            svg{
                                font-size: 20px;
                                min-width: 27px;
                                margin: 0 10px 0 0;
                            }
                        }

                        .btn_back{
                            background-color: $grey;
                            width: 25px;
                            height: 25px;
                            border-radius: 4px;
                            font-size: 16px;
                            color: white;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            margin: 0 20px 0 40px;
                            cursor: pointer;

                            svg{
                                margin: auto;
                            }
                        }

                        &.ns-Settings{
                            border-top: 5px solid #f9f9f9;
                            height: 65px;
                            max-height: 65px;
                        }
                    }
                }
            }
        }

        #logo{
            width: 100%;
            margin: 2rem auto;

            svg{
                width: auto;
                margin: auto 27%;
            }

        }
    }

    #darkflow{
        @include media-breakpoint-up(sm){
            display: none;
        }
    }

}