@import '~@betwixt/common/src/scss/variables.scss';

#Signup{

    margin-bottom: 20px;


    h4{
        font-size: 25px !important;
    }

    p{
        font-size: 20px !important;
        line-height: 28px !important;
    }

    .terms{
        margin: 2rem auto 0;
    }

}