@import '~@betwixt/common/src/scss/variables.scss';

@mixin row_column{
    .row{
        margin-right: auto;
        margin-left: auto;
        .column{
            padding-right: 10px;
            padding-left: 0;

            @include media-breakpoint-down(sm) {
                padding: 0;
                margin-bottom: 10px;
            }

            &:last-child{
                padding-right: 0;
            }
        }
    }
}

.table{
    color: $grey_medium;

    header{
        padding: 7px !important;
        color: #666666;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid $grey_medium;
        text-transform: capitalize;

        @include row_column;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .content{
        @include row_column;
    }

    .title-group{
        font-size: 20px;
        color: #0084fa;
        padding: 20px 10px 10px;
        border-bottom: 1px solid $grey_medium;
        text-transform: capitalize;
        margin-bottom: 5px;

        &.with-description{
            padding: 20px 10px 0;
            border-bottom: none;
        }
    }

    .description-group{
        font-size: 14px;
        color: $grey_medium;
        padding: 0 10px 10px;
        border-bottom: 1px solid $grey_medium;
        margin-bottom: 3px;
    }

    & > div{
        
        &:last-child{
            border: none;
        }
    }

}

.data-row{
    margin: 5px 0;
    width: 100%;
}


.term-grid {
    margin: auto;
    display: grid;
    grid-gap: 0 10px;
    color: #222;
    border-bottom: 1px solid #ddd;
    padding: 1em 0;
    
    &.col3-first0{
        grid-template-columns: 0fr 6fr auto;
    }
    &.col3{
        grid-template-columns: 6fr 2fr auto;
    }
    &.col3-auto{
        grid-template-columns: 6fr auto auto;
    }
    &.col4-first0{
        grid-template-columns: 0fr 6fr 1fr auto;
    }
    &.col4{
        grid-template-columns: 6fr 3fr 1fr auto;
    }
    &.col5-first0{
        grid-template-columns: 0fr 6fr 1fr 1fr auto;
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        grid-gap: 0.5em;
    }

    label {
        font-weight: 800;

        &:first-child {
            text-align: right;

            @include media-breakpoint-down(sm) {
                text-align: left;
                margin: 0;
            }
        }
    }
    .grid-header {
        text-decoration: underline;
        border-bottom: unset; 

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
}
